.App {
  max-width: 100%;
  /* max-width: 1440px; */
}

span.MuiFormControlLabel-label {
  width: 100% !important;
  display: block;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../src/assets/fonts/Lato-Regular.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato';
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
}

/* div.rec-carousel-item-visible{
  margin: 0px 10px;
} */

div.rec.rec-carousel {
  position: relative;
  overflow: hidden;
}

/* div.rec.rec-slider-container:hover button.rec.rec-arrow.rec.rec-arrow-left {
  transform: translateX(100%);
} */


/* button.rec.rec-arrow.rec.rec-arrow-right:hover{
  transform: translateX(-100%);
} */
button.rec.rec-arrow.rec.rec-arrow-left {
  background-color: white !important;
  color: black !important;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  left: 0px;
  position: absolute;
  z-index: 20;
  margin-top: -80px;
}

button.rec.rec-arrow.rec.rec-arrow-right {
  background-color: white !important;
  color: black !important;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  position: absolute;
  right: 0px;
  margin-top: -80px;
  z-index: 20;
}

div.rec.rec-pagination {
  display: none;
}

div.rec.rec-slider-container {
  margin: 0px !important;
}

.rdrNextButton i{
  margin: 0px !important;
}

@media (min-width:575.98px) {
  div.rec.rec-item-wrapper {
    height: 565px;
  }
}




/* CSS for screens smaller than 576 pixels */
@media (max-width: 575.98px) {

  button.rec.rec-arrow.rec.rec-arrow-left,
  button.rec.rec-arrow.rec.rec-arrow-right {
    width: 30px !important;
    height: 30px !important;
    min-width: 0px;
    min-height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    /* margin-top: 0; */
  }

  /* Your styles for small screens go here */
  /* For example, you can target and style specific elements or adjust layout */

}