.search-flight-fields {
  height: 112px;
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.search-flight-fields .from {
  height: 56px;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid #d0d5dd;
  border-bottom: none;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.search-flight-fields .from .text {
  width: inherit;
  height: 24px;
  color: #222936;
  font-size: 16px;
  font-family: Inter;
  line-height: 24px;
  border: none;
}

.search-flight-fields .from .text::placeholder {
  color: #222936;
  font-size: 16px;
  font-family: Inter;
}

.search-flight-fields .from .text:focus {
  outline: none;
}

.search-flight-fields .from .text:focus::placeholder {
  color: transparent;
}

.search-flight-fields .from .from-class-options-group {
  position: absolute;
  width: inherit;
  height: 320px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  /* margin-top: 114px; */
  overflow: scroll;
  z-index: 152;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  cursor: pointer;
}

.search-flight-fields .swap-icon {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  margin-top: -14px;
  margin-left: 85%;
  border: 1px solid #d1d6de;
  background: #fff;
  transform: rotate(90deg);
  cursor: pointer;
}

.search-flight-fields .swap-icon-arabic {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  margin-top: -14px;
  /* margin-left: 85%; */
  border: 1px solid #d1d6de;
  background: #fff;
  transform: rotate(90deg);
  cursor: pointer;
  left: 0;
  margin-left: 30px;
}
.search-flight-fields .swap-icon .swap-horiz-icon {
  color: #0088ff;
  transform: rotate(90deg);
}

.search-flight-fields .swap-icon-arabic .swap-horiz-icon {
  color: #0088ff;
  transform: rotate(90deg);
}
.search-flight-fields .to {
  height: 56px;
  width: 100%;
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.search-flight-fields .to .text {
  width: inherit;
  height: 24px;
  color: #222936;
  font-size: 16px;
  font-family: Inter;
  line-height: 24px;
  border: none;
}

.search-flight-fields .to .text::placeholder {
  color: #222936;
  font-size: 16px;
  font-family: Inter;
}

.search-flight-fields .to .text:focus {
  outline: none;
}

.search-flight-fields .to .text:focus::placeholder {
  color: transparent;
}

.search-flight-fields .to .to-class-options-group {
  position: absolute;
  width: 100%;
  height: 320px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  overflow: scroll;
  z-index: 152;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  cursor: pointer;
}

.source-destination-error {
  margin-left: 4px;
}
