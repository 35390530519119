.sectionHotel {
  /* height: 172px; */
  /* width: 1140px; */
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: flex-start; */
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-radius: 0rem 0.5rem 0.5rem 0.5rem;
  /* box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.16); */
  background: rgba(255, 255, 255, 0.7);
  /* backdrop-filter: blur(0.5rem); */
  flex-wrap: wrap;
  flex: 6;
  padding: 30px;
  width: "100%";
  background-color: "blue";
  /* justify-content: space-between; */
}

.mainSection {
  border-radius: 0rem 0.5rem 0.5rem 0.5rem;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
}

.modifySearch {
  /* box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.16); */
  background: rgba(255, 255, 255, 0.7);
  /* backdrop-filter: blur(0.5rem); */
  padding: 0 40px;
  margin-top: 0px !important;
  padding-top: 20px;
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}

.sectionHotel .search-hotels {
  /* width: 450px; */
  height: 112px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 2;
  cursor: pointer;
  position: relative;
}

/* @media (max-width: 1370px) {
  .sectionHotel .search-hotels {
    width: 350px;
    
  }
} */
.sectionHotel .search-hotels .title {
  /* width: 380px; */
  height: 17px;
  color: #667085;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 16px 16px 0px 16px;
}

/* @media (min-width: 1370px) {
  .sectionHotel .search-hotels .title {
    width: 380px;
  }
} */

.sectionHotel .search-hotels .input-search-hotel {
  /* width: 380px; */
  height: 24px;
  color: #667085;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px 16px 0px 14px;
}

/* @media (min-width: 1370px) {
.sectionHotel .search-hotels .input-search-hotel {
    width: 380px;
  }
} */
.sectionHotel .search-hotels .input-search-hotel .text {
  color: #344054;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  /* width: 380px; */
  height: 50px;
  cursor: pointer;
}

/* @media (min-width: 1370px) {
  .sectionHotel .search-hotels .input-search-hotel .text {
    width: 380px;
  }
} */

.sectionHotel .search-hotels .input-search-hotel .text::placeholder {
  color: #344054;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sectionHotel .search-hotels .input-search-hotel .text:focus {
  outline: none;
}

.sectionHotel .search-hotels .input-search-hotel .text:focus::placeholder {
  color: transparent;
}

.sectionHotel .search-hotels .country {
  color: #344054;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.sectionHotel .check-in {
  /* width: 164px; */
  height: 112px;
  margin: 30px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
}

.sectionHotel .check-in .check-in-title {
  /* width: 132px; */
  height: 18px;
  margin: 20px 16px 0px 16px;
  display: flex;
}

.sectionHotel .check-in .check-in-title .text {
  color: #667085;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sectionHotel .check-in .check-in-title .calendar-icon {
  width: 18px;
  height: 18px;
  color: #344054;
  margin-left: 50px;
}

.sectionHotel .check-in .check-in-desc {
  /* width: 132px; */
  height: 34px;
  margin: 0px 16px 0px 16px;
}

.sectionHotel .check-in .check-in-date {
  /* width: 132px; */
  height: 29px;
  margin: 0px 16px 0px 16px;
  color: #344054;
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sectionHotel .check-in .check-in-date .text {
  color: #344054;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.sectionHotel .check-in .check-in-day {
  /* width: 132px; */
  height: 17px;
  margin: 0px 16px 0px 16px;
  color: #344054;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sectionHotel .check-out {
  /* width: 164px; */
  height: 112px;
  margin: 30px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
}

.sectionHotel .check-out .check-out-title {
  /* width: 132px; */
  height: 18px;
  margin: 20px 16px 0px 16px;
  display: flex;
}

.sectionHotel .check-out .check-out-title .text {
  color: #667085;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sectionHotel .check-out .check-out-title .calendar-icon {
  width: 18px;
  height: 18px;
  color: #344054;
  margin-left: 40px;
}

.sectionHotel .check-out .check-out-desc {
  /* width: 132px; */
  height: 34px;
  margin: 0px 16px 0px 16px;
}

.sectionHotel .check-out .check-out-date {
  height: 29px;
  margin: 0px 16px 0px 16px;
  color: #344054;
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sectionHotel .check-out .check-out-date .text {
  color: #344054;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.sectionHotel .check-out .check-out-day {
  /* width: 132px; */
  height: 17px;
  margin: 0px 16px 0px 16px;
  color: #344054;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sectionHotel .user-information {
  /* width: 164px; */
  height: 112px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
  flex: 1;
}

.sectionHotel .user-information .dropdown {
  /* width: 132px; */
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sectionHotel .user-information .dropdown .content1 {
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
}

.sectionHotel .user-information .dropdown .content1 .dropdown-title {
  color: #667085;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.sectionHotel .user-information .dropdown .content1 .dropdown-icon {
  width: 18px;
  height: 18px;
}

.sectionHotel .user-information .dropdown .content2 {
  height: 29px;
  color: #344054;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sectionHotel .user-information .dropdown .content2 .text {
  color: #344054;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.sectionHotel .search-hotel-button {
  width: 104px;
  height: 104px;
  border-radius: 106px;
  background: #1b1d52;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionHotel .search-hotel-button .search-icon {
  color: #fff;
}

.hotel-class-options-group {
  position: absolute;
  width: 100%;
  height: 200px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  margin-top: 8px;
  margin-left: -15px;
  overflow: scroll;
  z-index: 152;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  cursor: pointer;
}

.hotel-class-options-group .hotel-class-option-group-card {
  /* width: 416px; */
  height: 323px;
  display: "flex";
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.hotel-class-options-group .hotel-class-option-group-card .hotel-class-option {
  /* width: 384px; */
  height: 37px;
  color: #344054;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  display: inline-flex;
  justify-content: space-between;
  bottom: 100%;
  width: 100%;
  padding: 16px;
}

.hotel-class-options-group .hotel-class-option-group-card .hotel-class-option .hotel-text-box {
  height: 37px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  color: #344054;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.hotel-class-options-group .hotel-class-option-group-card .hotel-class-option .hotel-code {
  /* width: 36px; */
  height: 22px;
  border-radius: 2px;
  background: #eef7ff;
}

.hotel-class-options-group .hotel-class-option-group-card .hotel-class-option .hotel-code div {
  background: #E6E6FA;
  color: #1B1D52;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px;
}

.dropdown-content {
  margin-left: -158px;
  margin-top: 10px;
  width: 304px;
}

.calendarWrap {
  position: relative;
  font-family: "Lato", sans-serif;
  /* margin-top: -50px; */
  margin-left: 98px;
  border-color: #222936;
}

.calendarWrap.calender {
  position: absolute !important;
}

.hotelCalenderWrap {
  position: absolute !important;
}

.calendarWrap .ranges-calendar {
  margin-top: 15px;
  position: relative;
}

.calendarWrap .rdrDateRangePickerWrapper {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.calendarWrap .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  z-index: -10;
  display: none;
}

.calendarWrap .calendar-component .rdrCalendarWrapper .rdrDateDisplay .rdrDateInput input {
  cursor: auto;
  border: none;
  color: black;
}

.calendarWrap .calendar-component .rdrCalendarWrapper .rdrDateDisplay .rdrDateDisplayItemActive {
  cursor: auto;
  border: none;
}

.calendarWrap .calendar-component .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
  display: none;
}

.calendarWrap .calendar-component .rdrCalendarWrapper .rdrMonths .rdrMonth .rdrMonthName {
  font-size: 16px;
  text-align: center;
  color: black;
  margin-top: -50px;
  margin-bottom: 20px;
}

.calendarWrap .rdrCalendarWrapper {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.calendarWrap .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
  display: none;
}

.calendarWrap .rdrCalendarWrapper .rdrMonths .rdrMonth .rdrMonthName {
  font-size: 16px;
  text-align: center;
  color: black;
  margin-top: -50px;
  margin-bottom: 20px;
}

.calendarWrapHotel {
  position: relative;
  font-family: "Lato", sans-serif;
  margin-top: -50px;
  margin-left: 400px;
  border-color: #222936;
}

.rdrSelected {
  color: #1b1d52 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #ababb4 !important;
  font-weight: 800 !important;
}

.rdrDayToday .rdrDayNumber span:before {
  background: black !important;
}

.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  font-weight: 800 !important;
}

.rdrDay:hover {
  color: #4037acd6 !important;
}

.rdrDayDisabled {
  color: #4037acd6 !important;
}

.rdrDayHovered :hover {
  color: #4037acd6 !important;
}

.rdrDay.rdrDayDisabled.rdrDayHovered {
  color: #4037acd6 !important;
}

.rdrDayEndOfMonth.rdrDayActive {
  color: #4037acd6 !important;
}

.rdrDayEndPreview {
  color: #4037acd6 !important;
}

.rdrDayActive {
  color: #4037acd6 !important;
}

.stay-nights {
  background-color: #E6E6FA;
  border-radius: 1.2rem;
  padding: 0.3rem 0.9rem;
  height: 2.5rem;
  width: 8rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  align-self: center;
}