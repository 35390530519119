.images-container {
    display: flex;
    justify-content: center;
    width: 80%;
    height: 600px;
}

.hotel-view-container {
    width: 80%;
    height: 600px;
    margin: auto;
    align-items: center;
    /* border: 1px solid black;
    border-radius: 5px; */
    padding: 5px;
    padding-bottom: 10% !important;
    margin-bottom: 10%;
}

.about-container {
    width: 80%;
    height: auto;
    margin: auto;
    align-items: center;
    padding: 5px;
}

.amenities-container{
    display: flex;
    width: 80%;
    height: auto;
    margin: auto;
    align-items: center;
    padding: 5px;
}

.container{
    background-color: lightgray;
    width: 100%;
   padding-bottom: 10px;
   font-family: 'Lato', sans-serif;
}