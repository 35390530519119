.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.back-arrow-package {
    margin-top: 200px;
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
    margin-left: -50px;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s, margin-left 1.5s;
    cursor: pointer;
}

.back-arrow-package .back-arrow-package-icon {
    width: 34px;
    height: 34px;
    color: #525A6A;
}

/* .forward-arrow-package {
    /* position: absolute;
    margin-top: 200px;
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
    margin-left: 1168px;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s, margin-left 1.5s;
    cursor: pointer; */
/* } */ 
.packages{
    z-index: 100;
    
}
.forward-arrow-package .forward-arrow-package-icon {
    width: 34px;
    height: 34px;
    color: #525A6A;
}

.packages:hover .back-arrow-package {
    opacity: 1;
    margin-left: 0px;
    cursor: pointer;
}

.packages:hover .forward-arrow-package {
    opacity: 1;
    margin-left: 1087px;
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .flip-card:hover .flip-card-inner {
        transform: rotateY(0deg);
    }
}

.css-to4yru {
    background: #FAFAFA;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 24px;
    padding: 24px!important;
    padding-left: 170px!important;
    padding-right: 140px!important;
}

    
