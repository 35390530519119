.book-flight-card {
  border-radius: 8px;
  /* border: 1px solid #D0D5DD; */
  background: #fff;
  flex: 5;
}

.book-flight-card-container {
  flex: 5;
  padding: 24px 16px;
  display: inline-flex;
}

.book-flight-card-container-content1 {
  flex: 4.25;
  display: inline-flex;
  justify-content: space-between;
  justify-items: flex-start;
}

.book-flight-card-container-content2 {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: flex-end;
}

.book-flight-card-icon-one-way {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  /* width: 5%; */
}

.book-flight-card-image-one-way {
  height: 36px;
  width: 42px;
}

.book-flight-card-flight-details-one-way {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  /* width: 15%; */
  align-items: center;
  flex: 0.25;
}

.book-flight-card-flight-name-one-way {
  color: #344054;
  font-size: 14px;
  font-weight: 700;
}

.book-flight-card-flight-code-one-way {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}

.book-flight-card-place-details-one-way {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  /* width: 12.5%; */
  align-items: center;
}

.book-flight-card-place-time-one-way {
  color: #344054;
  font-size: 14px;
  font-weight: 700;
}

.book-flight-card-place-name-one-way {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}

.book-flight-card-time-details-one-way {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  /* background-color: yellow; */
  /* width: 40%; */
}

.book-flight-card-time-taken-one-way {
  display: inline-flex;
  gap: 4px;
  color: #344054;
  font-size: 14px;
  font-weight: 700;
  align-self: center;
}

.book-flight-card-way-one-way {
  display: inline-flex;
  justify-content: space-between;
  /* align-self: center; */
}

.book-flight-card-way-text-one-way {
  color: #1dac08;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 6px;
  /* background-color: #1DAC08; */
}

.book-flight-card-plane-line-icon-one-way {
  margin-top: 7px;
  margin-left: -12px;
}

.book-flight-card-flight-details-button-one-way {
  color: #4037acd6;
  font-family: "Lato", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.book-flight-card-baggage-options-one-way {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #667085;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
}

.book-flight-card-price-details-one-way {
  text-align: right;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-flight-card-price-info-one-way {
  display: inline-flex;
  color: #667085;
  gap: 4px;
  font-size: 12px;
  justify-content: flex-end;
}

.book-flight-card-info-icon-one-way {
  height: 16px;
  width: 16px;
}

.book-flight-card-price-one-way {
  color: #344054;
  font-size: 20px;
  font-weight: 800;
}

.book-flight-card-button {
  border-radius: 8px;
  background: #1b1d52;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  cursor: pointer;
  width: "100%";
  padding: "8px 12px";
  flex: 1;
}
