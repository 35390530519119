.modify-search-container {
  /* width: 1140px; */
  margin: 30px 150px 0px 150px;
  border-radius: 5px;
}

@media (max-width: 1370px) {
  .modify-search-container {
    margin: 30px 80px 0px 80px;
  }
}

.modify-search-container-multi-city {
  /* width: 1140px; */
  padding: 30px 150px 0px 150px;
  border-radius: 5px;
}

.modify-search-header {
  height: 54px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0px 30px;
  border-radius: 10px 10px 0px 0px;
  /* width: 1140px; */
}

.modify-search-title {
  margin-top: 30px;
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}

@media (max-width: 1370px) {
  .modify-search-title {
    margin-top: 15px;
  }
}

.modify-search-close-icon {
  width: 24px;
  height: 24px;
  color: #344054;
  margin-top: 30px;
  cursor: pointer;
}
@media (max-width: 1370px) {
  .modify-search-close-icon {
    margin-top: 15px;
  }
}
.multi-city-component .section2-flight {
  /* width: 1140px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0px 8px 8px 8px;
  background-color: #fff;
}

.multi-city-component .section2-flight .section2-flight-content1 {
  width: 100%;
  display: inline-flex;
  padding: 1.8rem 1.8rem 0rem 1.8rem;
  justify-content: space-between;
}

@media (max-width: 1370px) {
  .multi-city-component .section2-flight .section2-flight-content1 {
    padding: 0.2rem 1.8rem 0rem 1.8rem;
  }
}

.multi-city-component
  .section2-flight
  .section2-flight-content1
  .radio-button-group {
  display: flex;
  gap: 0.625rem;
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
  width: 80%;
}

.multi-city-component .section2-flight .section2-flight-content2 {
  margin-top: 1rem;
  padding: 0rem 1.8rem 0.8rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
  width: 100%;
}

.multi-city-component
  .section2-flight
  .section2-flight-content1
  .checkbox-button {
  width: 20%;
}

.multi-city-component .checkbox-group {
  display: inline-flex;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  width: 100%;
}

.multi-city-component .checkbox-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.37rem;
  margin-left: 2px;
}

.multi-city-component .checkbox-group input[type="checkbox"] {
  visibility: hidden;
}

.multi-city-component .checkbox-icon {
  position: absolute;
  top: 6px;
  height: 16px;
  width: 16px;
  right: 80px;
  border: solid #b8bec4;
  background-color: white;
}

.multi-city-component .checkbox-group input:checked ~ .checkbox-icon {
  background-color:#1b1d52;
  border: none;
}

.multi-city-component .checkbox-icon:after {
  content: "";
  position: absolute;
  display: none;
}

.multi-city-component .checkbox-group input:checked ~ .checkbox-icon:after {
  display: block;
}

.multi-city-component .checkbox-group .checkbox-icon:after {
  left: 5px;
  bottom: 4px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.multi-city-component .radio-group {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  display: inline-flex;
  gap: 0.25rem;
  margin-left: 0.25rem;
}

.multi-city-component .radio-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1.75rem;
  margin-top: 0.37rem;
}

.multi-city-component .radio-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.multi-city-component .radio-icon {
  position: absolute;
  top: 6px;
  left: 4px;
  height: 14px;
  width: 14px;
  background: #fff;
  border: 2px solid #b8bec4;
  border-radius: 50%;
}

.multi-city-component .radio-group input:checked ~ .radio-icon {
  margin-top: 2px;
  background-color: #1b1d52;
  border: none;
}

.multi-city-component .radio-icon:after {
  content: "";
  position: absolute;
  display: none;
}

.multi-city-component .radio-group input:checked ~ .radio-icon:after {
  display: block;
}

.multi-city-component
  .radio-button-group
  input:checked
  ~ .radio-label-background {
  display: block;
}

.multi-city-component .radio-group .radio-icon:after {
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #eeeeee;
}

.multi-city-component .radio-label-background {
  position: relative;
  cursor: pointer;
  width: 110px;
  height: 33px;
  display: inline-flex;
  border-radius: 6px;
}

.user-info {
  height: 112px;
  /* width: 200px; */
  position: relative;
}

/* .user-info-enlarge {
    height: 112px;
    width: 376px;
    position: relative;
} */

.multi-city-component .section2-flight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: none;
  background-color: #fff;
}

.section2-flight .section2-flight-content2 .search-flight-button {
  width: 104px;
  height: 104px;
  border-radius: 106px;
  background: #1b1d52;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section2-flight .section2-flight-content2 .search-flight-button .search-icon {
  width: 40px;
  height: 40px;
  color: #fff;
  margin: 30px 30px;
}
