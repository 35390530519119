/* Tooltip Container */
.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip Content */
  .tooltip-content {
    position: absolute;
    background-color: white;
    padding: 5px 0px;
    border-radius: 5px;
    z-index: 1;
    top: 100%; /* Place the tooltip below the target element */
    /* left: 50%; */
    /* left: -100%; */
    /* right: 200px; */
    transform: translateX(-70%);
    display: block;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
    width: max-content;
  }
  
  .tooltip-container:hover .tooltip-content {
    opacity: 1; /* Show the tooltip on hover */
  }
  