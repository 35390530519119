.multi-city-contents-home-2 {
  height: 112px;
  padding: 0px 30px 30px 30px;
  display: flex;
  gap: 16px;
  position: relative;
  margin-bottom: 24px;
  width: 100%;
}

.multi-city-add-another-city {
  height: 48px;
  border-radius: 8px;
  background: #e6e6fa;
  margin-top: 32px;
  cursor: pointer;
}

.plus-icon {
  width: 24px;
  height: 23px;
  margin: 12px 4px 12px 8px;
}

.another-city-text {
  color: #1b1d52;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 8px 12px 0px;
}

.multi-city-divider {
  width: 1px;
  height: 60px;
  background: #e7e7e7;
  margin-top: 30px;
  margin-right: 8%;
}

.multi-city-cross-icons {
  height: 24px;
  width: 24px;
  margin-top: 44px;
  margin-right: 8%;
  cursor: pointer;
}
