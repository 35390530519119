.baggage-info-parent-div {
  /* width: 1000px; */
  outline: none;
  background-color: "red";
}

.baggage-info-head-div {
  display: flex;
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: space-between;
}

.baggage-info-travel-route {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 8px;
}

.baggage-info-route-source {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.baggage-info-route-arrow {
  margin-top: 5px;
}

.baggage-info-route-destination {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.baggage-item-counter-div {
  display: flex;
  margin-left: 32px;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: #ffa400;
  color: var(--color, #fff);
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.baggage-details-accordian-content-parent-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  justify-content: space-between;
  max-height: 30vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.baggage-details-accordion-content-div {
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  max-width: 80vw;
  width: 400px;
}

.extra-baggage-cost {
  margin-left: 32px;
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: -10px;
}

.baggage-accordian-heading {
  display: flex;
}

.selected-wheight {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.selected-wheight-cost {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
  line-height: normal;
  margin-top: -2px;
}
