div.policies > :not(li){
    padding: '0 50px';
}
div.policies > ul {
    padding-left: 25px ;
}
/* .policies:not(:empty)::not(:empty) {
  
    font-size: 26px;
    font-weight: bold;
  }
   */
  
  
  
  