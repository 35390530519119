.baggage-info-pop-up-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 10px;
  background: #FFF;
  outline: 0;
}