.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  font-family: "Lato", sans-serif;
  /* width: 460px; */
  /* margin-left: 35%; */
  margin-top: 7%;
  outline: none;
  max-height: 70vh;
}

.card-login {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 460px;

  /* height: 510px; */
}

.card-title-login {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 6px;
  text-align: center;
}

.card-meta-title-login {
  font-size: 16px;
  margin-bottom: 32px;
  text-align: center;
  color: #525a6a;
}

.input-title-login-secondary {
  color: #344054;
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
  margin-top: 4%;
}

.text-field {
  width: 100%;
  -moz-appearance: textfield;
}

.error {
  margin-top: 2px;
  color: red;
  font-size: 12px;
}

.card-button-login-secondary {
  background-color: #1b1d52;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  height: 48px;
  margin-top: 37px;
}

.card-bottom-title-login {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #344054;
  margin-top: 20px;
  justify-content: center;
  width: auto;
}

.card-bottom-title-login2 {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #344054;
  margin-top: 32px;
  justify-content: center;
  width: auto;
}

.forgot-password {
  color: #4037acd6;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.register {
  color: #4037acd6;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .card-container {
    height: auto;
    padding: 16px;
  }

  .card {
    width: 100%;
    height: auto;
  }
}
