.selected-departure-flight-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.selected-departure-flight-container .content1 {
  display: inline-flex;
  justify-content: space-between;
}

.selected-departure-flight-container .content1 .section1 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.selected-departure-flight-container .content2 {
  height: 140px;
}

.selected-departure-flight-heading {
  color: #344054;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.selected-departure-flight-place {
  color: #344054;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.selected-departure-flight-change-flight {
  color: #08F;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
}