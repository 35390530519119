/* Tooltip Container */
.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Tooltip Content */
.tooltip-content {
  position: absolute;
  background-color: white;
  padding: 5px 0px;
  border-radius: 5px;
  z-index: 1;
  top: 100%;
  left: -100%;
  transform: translateX(-45%) !important;
  display: block;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
  width: max-content;
}

.tooltip-container:hover .tooltip-content {
  opacity: 1; /* Show the tooltip on hover */
}
@media screen and (min-width: 400px) {
  .tooltip-content {
    transform: translateX(-30%) !important;
  }
}
