.quick-filters-container {
  height: 76px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.quick-filters-heading {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.quick-filters-content {
  height: 33px;
  display: inline-flex;
}

.quick-filters-content1 {
  width: 100%;
  display: inline-flex;
  gap: 8px;
  flex-wrap: wrap;
}

.filter-button {
  border-radius: 20px;
  border: 1px solid #d0d5dd;
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0px;
}

.filter-button:hover {
  border: 1px solid #1b1d52;
  background: #e6e6fa;
  cursor: pointer;
}

.filter-button-selected {
  border-radius: 20px;
  border: 1px solid #1b1d52;
  background: #e6e6fa;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter-button-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 8px;
  display: inline-flex;
  gap: 4px;
}

.quick-filters-content2 {
  width: 10%;
  color: #525a6a;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
