.flight-result-sub-header {
  border-radius: 2px;
  background: #FFF;
  height: 41px;
  width: 100%;
  display: inline-flex;
  gap: 10%;
}

.flight-result-sub-header-content1 {
  width: 68%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #344054;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 2%;
}

.flight-result-sub-header-content2 {
  width: 20%;
  display: inline-flex;
  color: #344054;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  justify-content: center;
}
