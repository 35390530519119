.select-return-flight-container {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 42px;
  margin-bottom: 10px;
  margin-top: 32px;
  background: #08F;
}

.selected-departure-flight-roundTrip-content1 {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.selected-departure-flight-roundTrip-header {
  color: #344054;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.selected-departure-flight-roundTrip-meta-title {
  color: #525A6A;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.selected-departure-flight-roundTrip-content2 {
  color: #08F;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 24px;
  border: 1px solid #08F;
  background: #EAF5FF;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.select-departure-flight-roundTrip {
  display: inline-flex;
  justify-content: space-between;
  gap: 4px;
  height: 42px;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
}

.select-departure-flight-roundTrip-content1 {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.select-departure-flight-roundTrip-header {
  color: #344054;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.select-departure-flight-roundTrip-meta-title {
  color: #525A6A;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.select-departure-flight-roundTrip-content2 {
  color: #08F;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 24px;
  border: 1px solid #08F;
  background: #EAF5FF;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.selected-departure-flight-roundTrip-text {
  padding: 8px 16px;
}