.home-container {
}
@media screen and (orientation: landscape) {
  .home-container {
    /* transform: rotate(0deg); */
    /* transform-origin: top left;
        width: 100vh;
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        overflow: hidden; */
  }
}

.home-container .linear-gradient {
  width: 80vw;
  height: 80vh;
  border-radius: 3rem;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    #11283c 45.31%,
    #12293d 100%
  );
}

.home-container .text-header {
  position: absolute;
  width: 60vw;
  z-index: 1;
  margin-top: 12rem;
  margin-left: 7rem;
  display: flex;
  flex-direction: column;
  gap: 0.01rem;
}

.home-container .header1 {
  width: 60vw;
  color: #fff;
  font-size: 3rem;
  font-family: "Abel", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: "red";
}

.home-container .header2 {
  width: 60vw;
  color: #fff;
  font-size: 5rem;
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  color: "red";
}

.home-container .header3 {
  width: 60vw;
  color: #fff;
  font-size: 3rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: "red";
}

.home-content {
  margin-top: 136px;
}

.home-content-enlarge {
  margin-top: 304px;
}

.recent-searches {
  position: absolute;
  margin-top: -180px;
  margin-left: 125px;
  height: 174px;
  width: 1140px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.travel-perfection-background {
  width: 800px;
  height: 420px;
  margin-top: -191px;
  background: url("../../assets/images/travel-preference.svg") 50% / cover
    no-repeat;
  position: absolute;
  margin-left: 287px;
}

.find-travel-perfection {
  position: absolute;
  width: 1140px;
  height: 350px;
  margin-top: -160px;
  margin-left: 125px;
}

.newsletter {
  position: absolute;
  width: 200px;
  height: 379px;
  margin-left: -32px;
  margin-top: 300px;
  background: linear-gradient(
    180deg,
    rgba(226, 241, 255, 0) 0%,
    #d9ecfc 53.65%,
    rgba(247, 247, 247, 0) 100%
  );
}

.recent-articles-and-blogs {
  width: 1140px;
  margin-left: 125px;
  margin-top: 760px;
}

.gradient1 {
  position: relative;
  height: 380px;
  width: 380px;
  border-radius: 380px;
  margin-left: 1007px;
  background: rgba(0, 136, 255, 0.4);
  filter: blur(142px);
}

.gradient2 {
  height: 380px;
  width: 380px;
  margin-top: -314px;
  margin-left: -64px;
  border-radius: 380px;
  background: rgba(1, 158, 236, 0.7);
  filter: blur(142px);
}

.gradient3 {
  position: relative;
  height: 380px;
  width: 380px;
  border-radius: 380px;
  margin-right: -64px;
  background: rgba(0, 255, 238, 0.6);
  filter: blur(142px);
  margin-top: -109px;
  margin-left: 1000px;
}

.gradient4 {
  height: 200px;
  width: 380px;
  border-radius: 380px;
  background: rgba(61, 88, 219, 0.4);
  filter: blur(142px);
  margin-top: -120px;
}

.footer {
  width: 1440px;
  height: 329px;
  margin-top: -150px;
  background: #373a40;
  margin-left: -32px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}