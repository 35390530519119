.container-search-result-flights {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
  margin-left: 300px;
  margin-bottom: 200px;
}

.css-39bbo6 {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: white !important;
}

.css-13y7nig {
  user-select: none;
  width: 20px !important;
  /* height: 1em; */
  /* display: inline-block; */
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  /* display: flex; */
}

.jss515 {
  border: 1px solid #08f;
  cursor: pointer;
  display: inline-flex;
  padding: 8px 6px !important;
  border-radius: 8px;
  justify-content: center;
}

.book-flight-card-price-info {
  color: #667085;
  display: inline-flex;
  font-size: 9px !important;
  gap: 4px;
  justify-content: flex-end;
}

.makeStyles-subContainer-23 {
  margin: 0px 150px;
  margin-left: 170px !important;
}
.css-q50n5p-MuiSvgIcon-root {
  color: #4037acd6 !important;
}