.sidebar-timings-meta-card {
  margin: 8px;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.timings-value {
  display: flex;
  flex-direction: column;
  color: #344054;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.timings-icon {
  width: 24px;
  height: 24px;
  margin-left: 38%;
}

.sidebar-time-price {
  font-weight: 600;
}