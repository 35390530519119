.search-widget-container {
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.newContainer {
  display: block;
}

.card-component-flight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-start; */
}

.card-component-hotel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-start; */
}

.section1 {
  display: flex;
  gap: 0.25rem;
}

.section1 .select-flight {
  border-radius: 0.3rem 0.3rem 0rem 0rem;
  background-color: #596977;
  backdrop-filter: blur(0.25rem);
  display: flex;
  cursor: pointer;
}

.section1 .select-flight-active {
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border-bottom: 0.12rem solid #1b1d52;
  background: #1b1d52;
  display: flex;
  cursor: pointer;
}

.section1 .select-flight .select-flight-icon {
  margin: 1rem 1rem 1rem 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.section1 .select-flight .text {
  margin: 1rem 0rem 1rem 1rem;
  color: #fff;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
}

.section1 .select-flight-active .select-flight-icon {
  margin: 1rem 1rem 1rem 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.section1 .select-flight-active .text {
  margin: 1rem 0rem 1rem 1rem;
  color: #fff;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
}

.section1 .select-hotel {
  border-radius: 0.3rem 0.3rem 0rem 0rem;
  background-color: #596977;
  backdrop-filter: blur(0.25rem);
  display: flex;
  cursor: pointer;
}

.section1 .select-hotel-active {
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border-bottom: 0.12rem solid #1b1d52;
  background: #1b1d52;
  display: flex;
  cursor: pointer;
}

.section1 .select-hotel .select-hotel-icon {
  margin: 1rem 1rem 1rem 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.section1 .select-hotel .text {
  margin: 1rem 0rem 1rem 1rem;
  color: #fff;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
}

.section1 .select-hotel-active .select-hotel-icon {
  margin: 1rem 1rem 1rem 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.section1 .select-hotel-active .text {
  margin: 1rem 0rem 1rem 1rem;
  color: #fff;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
}
