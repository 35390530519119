.rating-row{
    display: flex;
    align-items: flex-start;

}

.rating-row-item{
    margin: 5px;
}
.star-rating-icon{
    height: 15px;
    width: 15px;
}

.marker-icon{
    height: 25px;
    width: 25px;
}