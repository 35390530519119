.card-otp {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  padding: 32px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
  margin-left: 35%;
  width: 28%;
  outline: 0;
  font-family: "Lato", sans-serif;
}

.card-otp-arabic {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  padding: 32px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
  margin-right: 35%;
  width: 28%;
  outline: 0;
  font-family: "Lato", sans-serif;
}
.card-otp-content1 {
  height: 214px;
}

.card-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
}

.card-meta-title {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  color: #525a6a;
}

.input-style {
  border: 1px solid #000;
  border-radius: 10px;
  color: #000;
  font-size: 35px;
  height: 60px;
  margin: 15px 8px 5px;
  text-align: center;
  width: 80% !important;
  -moz-appearance: textfield;
}

.card-button-otp {
  background-color: #1b1d52;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  margin-top: 32px;
  width: 100%;
  height: 48px;
}

.card-bottom-title-otp {
  align-items: center;
  color: #344054;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
}

.secondary-text-otp {
  color: #4037acd6;
  cursor: pointer;
}

.secondary-text-otp-disabled {
  color: #525a6a;
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional, in case you want to adjust the spacing */
}

@media screen and (max-width: 480px) {
  /* .card-container {
        height: auto;
        padding: 16px;
    }

    .card {
        width: 100%;
        height: auto;
    } */
}
