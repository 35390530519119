.multi-city-content2 {
  /* width: 1080px; */
  height: 112px;
  padding: 0px 30px 30px 30px;
  display: flex;
  gap: 16px;
  position: relative;
  margin-bottom: 24px;
  width: 100%;
}

@media (max-width: 1370px) {
  .multi-city-content2 {
    padding: 0px 30px 0px 30px;
    margin-bottom: 18px;
  }
}
.multi-city-add-another-city {
  width: 152px;
  height: 48px;
  border-radius: 8px;
  background: #e6e6fa;
  margin-top: 32px;
  cursor: pointer;
  display: flex;
}

.plus-icon {
  width: 24px;
  height: 23px;
  margin: 12px 4px 12px 8px;
}

.another-city-text {
  color: #1b1d52;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 8px 12px 0px;
}

.multi-city-dividers {
  width: 1px;
  height: 60px;
  background: #e7e7e7;
  /* margin-left: 345px; */
  margin-top: 30px;
  position: absolute;
  right: 0;
  margin-right: 10%;
}

.multi-city-dividers-Arabic {
  width: 1px;
  height: 60px;
  background: #e7e7e7;
  /* margin-left: 345px; */
  margin-top: 30px;
  position: absolute;
  left: 0;
  margin-left: 10%;
}

.multi-city-cross-icon {
  height: 24px;
  width: 24px;
  margin-top: 44px;
  margin-right: 16px;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 5%;
}

.multi-city-cross-icon-Arabic {
  height: 24px;
  width: 24px;
  margin-top: 44px;
  margin-left: 16px;
  cursor: pointer;
  position: absolute;
  left: 0;
  margin-left: 5%;
}
