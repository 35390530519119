.button-background-non-active {
    padding: 12px 24px;
    border-radius: 12px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    color: #525A6A;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.button-background-active {
    padding: 12px 24px;
    border-radius: 12px;
    border: 1px solid #D0D5DD;
    background: #E6E6FA;
    color: #1B1D52;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}