.flightResultHeader {
  font-family: "Lato", sans-serif;
  padding: 20px 0px;
}

.flightResultHeaderContainer {
  width: 100%;
  border-radius: 5px;
  background: #e6e6fa;
  backdrop-filter: blur(3px);
  display: inline-flex;
  align-items: center;
  gap: 30px;
  height: 80px;
}

.flight-result-header-content1 {
  width: 30%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
}

.flight-result-header-content1-arabic {
  width: 30%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
}

.flight-result-header-code {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.flight-result-header-name {
  color: #525a6a;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.flight-result-header-icon {
  margin: 0px 32px;
  width: 24px;
  height: 24px;
}

.flight-result-header-divider {
  background: #d0d5dd;
  width: 1px;
  height: 48px;
}

.flight-result-header-content2 {
  width: 55%;
  display: inline-flex;
  gap: 50px;
  align-items: center;
}

.flight-result-header-content2-section1 {
  width: 15%;
  display: flex;
  flex-direction: column;
}

.flight-result-header-content2-section2 {
  width: 15%;
  display: flex;
  flex-direction: column;
}

.flight-result-header-content2-section3 {
  width: 18%;
  display: flex;
  flex-direction: column;
}

.flight-result-header-content2-section4 {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.flight-result-header-content2-heading {
  color: #667085;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.flight-result-header-content2-value {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.flight-result-header-content3 {
  width: 15%;
  display: flex;
  align-items: center;
}

.flight-result-header-modify-search {
  width: 80%;
  border-radius: 8px;
  background: #1b1d52;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modify-search-text {
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
