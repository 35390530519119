.popup-content {
  color: black;
}

.header-div {
  display: flex;
  justify-content: space-between;
}

.tab-layout {
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fair-rule-popup-heading-div {
  display: flex;
  justify-content: space-between;
}