/* .card-signup-secondary {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 32%;
  height: 690px;
  margin-top: 5%;
  margin-left: 35%;
  font-family: "Lato", sans-serif;
  outline: 0;
} */
.card-signup-secondary {
  max-width: 600px;
}

.card-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 6px;
  text-align: center;
}

.card-meta-title {
  font-size: 16px;
  text-align: center;
  color: #525a6a;
}

.input-title-signup {
  color: #344054;
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 32px;
  font-weight: 500;
}

.text-field-signup {
  /* width: 100%; */
  width: 200px !important;
  height: 50px !important;
  -moz-appearance: textfield;
}

.error {
  margin-top: 2px;
  color: red;
  font-size: 12px;
}

.card-button {
  background-color: #1b1d52;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 40px;
  width: 100%;
  height: 48px;
}

.card-bottom-title {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #344054;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  color: #1b1d52;
  cursor: pointer;
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  .card-container {
    height: auto;
    padding: 16px;
  }

  .card {
    width: 100%;
    height: auto;
  }
}

.sign-up-container {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  font-family: Lato, sans-serif;
  height: fit-content;
  margin-left: 35%;
  margin-top: 1%;
  outline: 0;
  padding: 32px;
  width: 100%;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  font-size: smaller !important;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 10px 14px !important;
  padding-left: 0px !important;
  padding-left: 0;
}

.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 18px;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.card-button-register {
  background-color: #1b1d52;
  border: none;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  height: 48px;
  margin-top: 20px;
  padding: 8px 16px;
  width: 100%;
}

.input-title-signup {
  color: #344054;
  font-size: 14px;
  margin-bottom: 4px;
  margin-top: 15px;
  font-weight: 500;
}

.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 18px !important;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.card-bottom-title {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #344054;
  margin-top: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.firstNameAndLastNameContainer,
.phoneAndEmailContainer,
.passwordAndConfirmPasswordContainer {
  display: flex;
  gap: 20px;
}

.firstNameContainer,
.lastNameContainer,
.phonenumberContainer,
.emailContainer,
.passwordContainer,
.confirmPasswordContainer {
  width: 200px;
}