.section2-flight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.16);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(0.5rem);
}

.section2-flight-content1 {
  width: 100%;
  display: inline-flex;
  padding: 1.8rem 1.8rem 0rem 1.8rem;
  justify-content: space-between;
}

.section2-flight-content1 .radio-button-group {
  display: flex;
  gap: 0.625rem;
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
  width: 80%;
}

.section2-flight-content2 {
  margin-top: 1.5rem;
  padding: 0rem 1.8rem 1.8rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
}

.checkbox-button {
  width: 20%;
}

.checkbox-group {
  display: inline-flex;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  width: 100%;
}

.checkbox-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.37rem;
  margin-left: 2px;
}

.checkbox-group input[type="checkbox"] {
  visibility: hidden;
}

.checkbox-icon {
  position: absolute;
  top: 6px;
  height: 16px;
  width: 16px;
  right: 80px;
  background: #b8bec4;
  border: solid #fff;
}

.checkbox-group input:checked ~ .checkbox-icon {
  background-color:#1b1d52;
  border: none;
}

.checkbox-icon:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-group input:checked ~ .checkbox-icon:after {
  display: block;
}

.checkbox-group .checkbox-icon:after {
  left: 5px;
  bottom: 4px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.radio-group {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  display: inline-flex;
  gap: 0.25rem;
  margin-left: 0.25rem;
}
.radio-group-arabic {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1.25rem;
  display: inline-flex;
  gap: 0.25rem;
  margin-right: 0.25rem;
}
.radio-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1.75rem;
  margin-top: 0.37rem;
}

.radio-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-group-arabic input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-icon {
  position: absolute;
  top: 0.37rem;
  left: 0.25rem;
  height: 0.875rem;
  width: 0.875rem;
  background: #b8bec4;
  border: 0.12rem solid #fff;
  border-radius: 50%;
}

.radio-group input:checked ~ .radio-icon {
  margin-top: 0.12rem;
  background-color: #1b1d52;
  border: none;
}

.radio-group-arabic input:checked ~ .radio-icon {
  margin-top: 0.12rem;
  background-color: #1b1d52;
  border: none;
}

.radio-icon:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-group input:checked ~ .radio-icon:after {
  display: block;
}

.radio-group-arabic input:checked ~ .radio-icon:after {
  display: block;
}
.radio-group input:checked ~ .radio-label-background {
  display: block;
}

.radio-group-arabic input:checked ~ .radio-label-background {
  display: block;
}

.radio-group .radio-icon:after {
  top: 0.25rem;
  left: 0.25rem;
  width: 0.37rem;
  height: 0.37rem;
  border-radius: 50%;
  background: #eeeeee;
}

.radio-group-arabic .radio-icon:after {
  top: 0.25rem;
  left: 0.25rem;
  width: 0.37rem;
  height: 0.37rem;
  border-radius: 50%;
  background: #eeeeee;
}

.radio-label-background {
  position: relative;
  cursor: pointer;
  width: auto;
  height: 33px;
  display: inline-flex;
  border-radius: 6px;
}

.user-info {
  position: relative;
  flex: 1.25;
}

.user-info-enlarges {
  position: relative;
  flex: 2;
}

@media (max-width: 1370px) {
  .user-info-enlarges {
    flex: 1.5;
  }
}

.section2-flight-content2 .search-flight-button {
  border-radius: 106px;
  background: #1b1d52;
  margin-top: 0.25rem;
  width: 104px;
  height: 104px;
  display: flex;
  cursor: pointer;
}

.section2-flight-content2 .search-flight-button .search-icon {
  color: #fff;
  margin: 1.8rem 1.8rem;
  width: 40px;
  height: 40px;
}

.calendarWrap {
  position: relative;
  font-family: "Lato", sans-serif;
  margin-left: 180px;
  border-color: #222936;
  z-index: 2;
  margin-top: -30px;
  margin-right: calc(100% - 70%);
}

.calendarWrap .range-calendar {
  margin-top: -45px;
}

.calendarWrap .rdrDateRangePickerWrapper {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.calendarWrap .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  z-index: -10;
  display: none;
}

.calendarWrap
  .calendar-component
  .rdrCalendarWrapper
  .rdrDateDisplay
  .rdrDateInput
  input {
  cursor: auto;
  border: none;
  color: black;
}

.calendarWrap
  .calendar-component
  .rdrCalendarWrapper
  .rdrDateDisplay
  .rdrDateDisplayItemActive {
  cursor: auto;
  border: none;
}

.calendarWrap
  .calendar-component
  .rdrCalendarWrapper
  .rdrMonthAndYearWrapper
  .rdrMonthAndYearPickers {
  display: none;
}

.calendarWrap
  .calendar-component
  .rdrCalendarWrapper
  .rdrMonths
  .rdrMonth
  .rdrMonthName {
  font-size: 16px;
  text-align: center;
  color: black;
  margin-top: -50px;
  margin-bottom: 20px;
}

.calendarWrap .rdrCalendarWrapper {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.calendarWrap
  .rdrCalendarWrapper
  .rdrMonthAndYearWrapper
  .rdrMonthAndYearPickers {
  display: none;
}

.calendarWrap .rdrCalendarWrapper .rdrMonths .rdrMonth .rdrMonthName {
  font-size: 16px;
  text-align: center;
  color: black;
  margin-top: -50px;
  margin-bottom: 20px;
}

.calenderWrapModifyFlight {
  position: relative;
  font-family: "Lato", sans-serif;
  margin-left: 180px;
  border-color: #222936;
  z-index: 2;
  margin-top: -50px;
  margin-right: calc(100% - 70%);
}
