.card-passenger-mobile {
    border-radius: 16px;
    margin-top: -16px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    /* z-index: 100x; */
}

.card-content-mobile {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.card-content-mobile .section-1-mobile {
    display: flex;
    flex-direction: column;
    gap: 12px
}

.heading {
    color: #344054;
    font-size: 0.9rem;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}

.list-numbers-mobile {
    display: inline-flex;
    gap: 10px;
    flex-wrap: wrap;
}

.non-active-valid {
    border-radius: 8px;
    width: 26px;
    height: 24px;
    text-align: center;
    color: #344054;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
}

.non-active-invalid {
    border-radius: 8px;
    background: gray;
    width: 26px;
    height: 24px;
    text-align: center;
    color: #344054;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
}

.active-mobile {
    border-radius: 8px;
    background: #1b1d52;
    color: #F5F6F7;
    width: 26px;
    height: 24px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    padding-top: 3px;
    cursor: pointer;
    font-size: 15px;
}

.errors {
    width: 552px;
    height: 30px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.travellers-card-error {
    margin-top: -10px;
    margin-left: -40px
}

.bottom {
    border-radius: 12px;
    border: 1px solid #1b1d52;
    background: #1b1d52;
    width: 100%;
    padding: 8px 0px;
}

.apply-text {
    color: white;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    cursor: pointer;
}