.book-flight-card-irt {
  height: 145px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
}

.book-flight-card-container-irt {
  padding: 24px 16px;
  display: inline-flex;
  background-color: gold;
  flex: 5;
}

.book-flight-card-container-content1-irt {
  flex: 4;
  display: inline-flex;
  justify-content: space-between;
}

.book-flight-card-container-content2-irt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  flex: 0.75;
}

.book-flight-card-icon-irt {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 5%; */
}

.book-flight-card-image-irt {
  height: 36px;
  width: 42px;
}

.book-flight-card-flight-details-irt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  /* width: 15%; */
  align-items: center;
  flex: 0.25;
}

.book-flight-card-flight-name-irt {
  color: #344054;
  font-size: 14px;
  font-weight: 700;
}

.book-flight-card-flight-code-irt {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}

.book-flight-card-place-details-irt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  /* width: 12.5%; */
  align-items: center;
}

.book-flight-card-place-time-irt {
  color: #344054;
  font-size: 14px;
  font-weight: 700;
}

.book-flight-card-place-name-irt {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}

.book-flight-card-time-details-irt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  /* width: 40%; */
}

.book-flight-card-time-taken-irt {
  display: inline-flex;
  gap: 4px;
  color: #344054;
  font-size: 14px;
  font-weight: 700;
  align-self: center;
}

.book-flight-card-way-irt {
  display: inline-flex;
  gap: 8px;
  align-self: center;
}

.book-flight-card-way-text-irt {
  color: #1dac08;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 6px;
}

.book-flight-card-plane-line-icon-irt {
  margin-top: 7px;
  margin-left: -12px;
}

.book-flight-card-flight-details-button-irt {
  color: #4037acd6;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  /* width: 15%; */
  cursor: pointer;
}

.book-flight-card-baggage-options-irt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #667085;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-flight-card-price-details-irt {
  text-align: right;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-flight-card-price-info-irt {
  display: inline-flex;
  color: #667085;
  gap: 4px;
  font-size: 12px;
  justify-content: flex-end;
}

.book-flight-card-info-icon-irt {
  height: 16px;
  width: 16px;
}

.book-flight-card-price-irt {
  color: #344054;
  font-size: 20px;
  font-weight: 800;
}

.book-flight-card-button-irt {
  border-radius: 8px;
  background: #1b1d52;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 37px;
  margin-left: 20%;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
