.book-flight-container {
  margin-top: 18px;
  width: 100%;
  display: inline-flex;
  gap: 3%
}

.book-flight-sidebar {
  width: 25%;
  margin-bottom: 80px;
}

.book-flight-result {
  width: 72%;
  margin-bottom: 80px;
}

.makeStyles-checkPrice-83 {
  font-size: 10px !important;
  font-family: Lato;
  font-weight: 400;
}
.css-f75dfd-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 18px !important;
  height:auto !important;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #FFF;
}
.MuiTypography-root.MuiTypography-body1.css-icnsqa-MuiTypography-root {
color: #4037acd6 !important;
}
.css-5ezzb5-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-5ezzb5-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #1b1d52 !important;
}
.css-53tbrc-MuiSlider-root {
  color: #1b1d52 !important;
}
