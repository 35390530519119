.blogs-card {
  border-radius: 10px 10px 10px 10px;
  border-right: 1px solid #d0d5dd;
  border-bottom: 1px solid #d0d5dd;
  border-left: 1px solid #d0d5dd;
  background: #fff;
  cursor: pointer;
}

.button-disabled {
  color: #767779;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.button-active {
  color: #24272a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.page-number-disabled-background {
  color: rgba(36, 39, 42, 0.5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page-number-active-background {
  border-radius: 2px;
  border: 1px solid #1b1d52;
  background: #e6e6fa;
  width: 24px;
  height: 24px;
  color: #1b1d52;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-input {
  width: 23.996px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid rgba(36, 39, 42, 0.35);
  background-color: #fafafa;
  text-align: center;
}

.go-button {
  color: rgba(36, 39, 42, 0.36);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  width: 18.997px;
}

/* .css-1l8hra {
  background: #FAFAFA;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 24px;
  padding: 70px!important;
  width: 100%;
  padding-left: 170px!important;
  padding-right: 150px!important;
}
.css-d9l8gs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%!important;
  padding: 10px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
} */
