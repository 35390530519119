.allImages  .rec.rec-arrow.rec.rec-arrow-right {
  margin-left: 20px;
  position: absolute;
  top: 50%;
  right: 100px;
}
.allImages  button:disabled {
  background-color: grey !important;
}
.allImages .rec.rec-arrow.rec.rec-arrow-left {
  margin-right: 20px;
  position: absolute;
  top: 50%;
  left: 100px;
}

.allImages {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rec-item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
