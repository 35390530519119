.header-card {
    height: 66px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.header-logo {
    margin-top: 8px;
    height: 50px;
    cursor: pointer;
}

.header-logo-icon {
    width: 100px;
    height: 50px;
    margin-left: 150px;
    background: 50% / cover no-repeat;
}

.header-items {
    height: 36px;
    margin-top: 15px;
    display: inline-flex;
    width: 35%;
    gap: 16px;
    color: #344054;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 150px;
}

.customer-support {
    width: 25%;
    display: flex;
    align-items: center;
    cursor: pointer;
}    

.faq {
    width: 10%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.country-dropdown {
    border-radius: 8px;
    border: 1px solid #DDD;
    padding: 8px;
    background: #FFF;
    width: 25%;
    height: 33px;
    display: inline-flex;
    justify-content: space-between;
    align-items: 'center';
    gap: 8px;
    color:  #344054;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.flag {
    width: 16px;
    height: 12px;
}

.country-dropdown-divider {
    width: 1px;
    height: 14px;
    background: #DDD;
}


.arrow-down-country-dropdown {
    margin: 10px 8px 8px 0px;
}

.login {
    width: 25%;
    height: 34px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: '20px';
}

.login-icon {
    margin: 9px 8px 8px 8px;
}

.login-text {
    color: #344054;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}