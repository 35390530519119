.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid var(--border-color, #d0d5dd);
  padding: 16px;
  width: 50%;
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-table th {
  background-color: #f2f2f2;
}

.route-parent-div {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid var(--border-color, #d0d5dd);
  background: #f5f5f5;
}

.route-source-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.route-destination-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.airline-image {
  margin-right: 10px;
}

.from-date-departure-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 6px;
}

.non-refundable-text {
  color: #344054;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.Cancel-charges-dropdown {
  width: 100%;
  padding: 16px;
}

.from-date-departure-text2 {
  color: #1b1d52;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}
