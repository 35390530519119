.button-background-non-active {
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
  color: #525a6a;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.button-background-active {
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #edf7ff;
  color: #1b1d52;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.MuiPickersYear-root button.MuiPickersYear-yearButton.Mui-selected {
  background-color: #1b1d52;
  color: white;
}

.MuiPickersYear-root button.Mui-selected:hover {
  background-color: #1b1d52;
  color: white;
}

.MuiDayCalendar-weekContainer button.MuiPickersDay-root.Mui-selected {
  background-color: #1b1d52;
  color: white;
}

.MuiDayCalendar-weekContainer button.MuiPickersDay-root:hover {
  background-color: #e6e6fa;
  color: black;
}
