.hotel-image {
  height: 300px;
  width: 90%;
  border-radius: 10px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 1px 5px 2px 5px;
}

.hotel-icons {
  height: 15px;
  width: 15px;
  margin: 10px 10px;
}

.hotel-description {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* word-spacing: 5px; */
}

.hotel-features {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  margin: 10px 4px 10px 4px;
  height: 30px;
}

.price-box {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  height: 200px
}

.price-box .price-body {
  /* height: 60px; */
  min-height: 80px;
  max-height: 80px;
}

.price-box .price-value {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: medium;
}

.price-box .price-footer {
  display: flex;
  justify-content: flex-end;
  color: darkgray;
}