.travel-date-home {
  height: 112px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  background: #fff;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

.calender-wrapper {
  position: absolute;
  z-index: 22;
}

.calender-wrapper-above {
  position: absolute;
  z-index: 22;
}
