.search-flight-sidebar {
    background-color: white;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.search-result {
    display: inline-flex;
    justify-content: space-between;
    font-style: normal;
    line-height: normal;
}

.sidebar-divider {
    height: 1px;
    background: #D0D5DD;
}

.search-result-number {
    color: #344054;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.search-result-clear-all {
    color: #08F;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.sidebar-filter {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.sidebar-filter-heading {
    display: inline-flex;
    justify-content: space-between;
    text-transform: uppercase;
}

.sidebar-filter-title {
    color: #08F;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.sidebar-filter-expand-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    color: #292D32;
    cursor: pointer;
}

.sidebar-filter .checkbox-content {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    gap: 10px;
}

.sidebar-filter .checkbox-group {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 1.25rem;
    display: inline-flex;
    width: 100% !important;
}

.sidebar-filter .checkbox-text {
    width: 100% !important;
    color: #344054;
    font-family: 'Lato', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 0.37rem;
    margin-left: 1.75rem;
}

.sidebar-filter .checkbox-group input[type=checkbox] {
    visibility: hidden;
}

.sidebar-filter .checkbox-icon {
    position: absolute;
    top: 0.37rem;
    background: #fff;
    border: solid #B8BEC4;
    margin-right: 140px;
}

.sidebar-filter .checkbox-group input:checked~.checkbox-icon {
    background-color: #1B1D52;
    border: none;
}

.sidebar-filter .checkbox-icon:after {
    content: "";
    position: absolute;
    display: none;
}

.sidebar-filter .checkbox-group input:checked~.checkbox-icon:after {
    display: block;
}

.sidebar-filter .checkbox-group .checkbox-icon:after {
    left: 0.3rem;
    bottom: 0.25rem;
    border: solid white;
    border-width: 0 0.12rem 0.12rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.view-all-airlines {
    color: #08F;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.sidebar-price-values {
    display: inline-flex;
    justify-content: space-between;
    color: #525A6A;
    margin-top: -12px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700;
}

.sidebar-trip-values {
    display: inline-flex;
    justify-content: space-between;
    color: #525A6A;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.sidebar-price-max-value {
    color: #525A6A;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.price-slider {
    width: 97.5%;
    margin: -10px 2.5% 0px 0px;
}

.trip-slider {
    width: 95%;
    margin: 0% 2.5%;
}

.sidebar-timings-container {
    width: 85%;
    height: 180px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sidebar-timings-container1 {
    display: inline-flex;
    gap: 5%;
}

.sidebar-timings-container2 {
    display: inline-flex;
    gap: 5%;
}

.sidebar-timings-card {
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    width: 88px;
    /* height: 84px; */
    cursor: pointer;
}

.sidebar-timings-card-active {
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    background-color: #EAF5FF;
    width: 88px;
    /* height: 84px; */
    cursor: pointer;
}