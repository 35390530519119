.room-selection-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.person-type {
  display: flex;
  justify-content: space-between;
}

.room-selection-parent-box {
  display: inline-flex;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  border-radius: 16px;
  background: #FFF;
  width: auto;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}