.faqHeading {
  --bs-blue: #435ebe;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #435ebe;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --loader-color: #1b1d52;
  --back-color: #435ebe52;
  --time: 5s;
  --size: 7px;
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center !important;
  pointer-events: initial;
  box-sizing: border-box;
  color: inherit;
  font-family: Roboto, sans-serif;
  font-weight: bolder;
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 1rem !important;
}

.tabHead {
  padding: "0 150px";
}

@media screen and (min-width: 1024px) {
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .tabHead {
    padding: "0 10px";
  }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .tabHead {
    padding: "0 10px";
  }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .tabHead {
    padding: "0 10px";
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .tabHead {
    padding: "0 10px";
  }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .tabHead {
    padding: "0 10px";
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .tabHead {
    padding: "0 2px";
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .tabHead {
    padding: "0 2px";
  }
}
