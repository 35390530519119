.book-flight-card {
  border-radius: 8px;
  /* border: 1px solid #D0D5DD; */
  background: #fff;
  width: 100%;
}

.book-flight-card-container {
  padding: 24px 16px;
  display: inline-flex;
  justify-content: space-between;
}

.book-flight-card-container-content1 {
  height: 94px;
  display: inline-flex;
  gap: 16px;
}

.book-flight-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-flight-card-image {
  height: 36px;
  width: 42px;
}

.book-flight-card-flight-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  /* width: 15%; */
  align-items: center;
}

.book-flight-card-flight-name {
  color: #344054;
  font-size: 14px;
  font-weight: 700;
}

.book-flight-card-flight-code {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}

.book-flight-card-place-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  /* width: 12.5%; */
  align-items: center;
}

.book-flight-card-place-time {
  color: #344054;
  font-size: 14px;
  font-weight: 700;
}

.book-flight-card-place-name {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}

.book-flight-card-time-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  /* width: 40%; */
}

.book-flight-card-time-taken {
  display: inline-flex;
  gap: 4px;
  color: #344054;
  font-size: 14px;
  font-weight: 700;
  align-self: center;
}

.book-flight-card-way {
  display: inline-flex;
  gap: 8px;
  align-self: center;
}

.book-flight-card-way-text {
  color: #1dac08;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 6px;
}

.book-flight-card-plane-line-icon {
  margin-top: 7px;
  margin-left: -12px;
}

.book-flight-card-flight-details-button {
  color: #4037acd6;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  /* width: 15%; */
  cursor: pointer;
}

.book-flight-card-baggage-options {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #667085;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-flight-card-price-details {
  text-align: right;
  font-family: "Lato", sans-serif;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-flight-card-price-info {
  display: inline-flex;
  color: #667085;
  gap: 4px;
  font-size: 12px;
  justify-content: flex-end;
}

.book-flight-card-info-icon {
  height: 16px;
  width: 16px;
}

.book-flight-card-price {
  color: #344054;
  font-size: 20px;
  font-weight: 800;
}

.book-flight-card-button {
  border-radius: 8px;
  background: #1b1d52;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 80%; */
  height: 37px;
  margin-left: 20%;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
